import styled from 'styled-components';
import React from 'react';

const StyledNav = styled.nav`
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 0px;
  @media (max-width: 991px) {
    padding: 0 8px;
    width: 100%;
  }
`;

const MobileContainer = styled.div`
  display: flex;
  justify-content: space-between;
  @media (min-width: 992px) {
    display: none;
  }
`;
const ImageA = styled.a`
  display: flex;
  justify-self: flex-end;
  max-width: 175px;
  > img {
    object-fit: contain;
  }
  @media (min-width: 768px) {
    display: none;
  }
`;

function Nav({ linkPrefix }) {
  return (
    <StyledNav>
      <MobileContainer>
        <ImageA href={`${linkPrefix}/`}>
          <img
            src="/images/act_am24_logo.png"
            alt="SOT Society of Toxicology logo"
          />
        </ImageA>
      </MobileContainer>
    </StyledNav>
  );
}

export default Nav;
