import styled, { css } from 'styled-components';
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedinIn, faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faPhone, faHome } from '@fortawesome/free-solid-svg-icons';
import { red } from '../styles';
import Nav from './Nav';

const StyledHeader = styled.header`
  font-size: 13px;
  font-family: 'Roboto', sans-serif;
  z-index: 100;
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
`;
const TopMenu = styled.ul`
  text-align: left;
  margin-left: 20px;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  > li {
    display: inline-block;
    ${(props) =>
      props.$year === '2023'
        ? css`
            margin-right: 35px;
            line-height: 51px;
          `
        : css`
            border-left: 1px solid #ebebeb;
            line-height: 40px;
            padding: 0 1em;

            &:last-child {
              border-right: 1px solid #ebebeb;
            }
          `}
  }
`;
const HeaderLink = styled.a`
  color: ${(props) => props.theme.colors.headerTopFont};
  display: flex;
  flex-direction: row;
  align-items: center;

  &:hover {
    color: ${(props) => props.theme.colors.headerLinkHover};
  }
`;
const StyledIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.headerIcon};
`;
const SkipNavigationWrapper = styled.div`
  text-align: left;
`;
const SkipNavigation = styled.a`
  padding: 0.75rem 2rem 0.75rem 2rem;
  z-index: 1000;
  position: absolute;
  top: -3.5rem;
  background: #c35113;
  color: white;
  transition-property: top;
  transition-duration: 0.5s;
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  :focus {
    top: -2px;
  }
`;
const Container = styled.div`
  max-width: 1170px;
`;
const Row = styled.div`
  align-items: center;
`;
const OrangeBreaker = styled.span`
  color: #fa6231;
  line-height: 24px;
`;
const ButtonRow = styled.div`
  max-width: 310px;
  display: flex;
  flex-direction: row;
  vertical-align: top;
  margin-left: 15px;
  margin-bottom: 20px;
`;
const ToXChangeIcon = styled.img`
  width: 20px;
`;
const HeaderTop = styled.div`
  border-top: ${(props) => props.theme.colors.headerTopBorderTop};
  background-color: ${(props) => props.theme.colors.headerTopBg};
  color: ${(props) => props.theme.colors.headerTopFont};
  border-bottom: ${(props) => props.theme.colors.headerTopBorderBottom};
  a {
    text-decoration: none;
    :hover {
      text-decoration: underline;
    }
  }
`;
const TopRowRigth = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }
`;
const SocialMediaIcons = styled.ul`
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  display: flex;
  padding: 0;
  flex-direction: row;
  justify-content: space-between;
  > li {
    display: inline-block;
  }
`;
const SocialIconList = styled.li`
  ${(props) =>
    props.$year === '2024'
      ? css`
          :hover {
            background: #00aced;
            a {
              color: #fff;
            }
          }
        `
      : ''};
`;
const SocialIcon = styled.a`
  width: auto;
  height: 35px;
  display: block;
  color: ${(props) => props.theme.colors.headerIcon};

  svg {
    height: 1.3em;
    width: 1.3em;
  }
`;
const LoginButton = styled.a`
  padding: 5px 15px;
  background-color: #84ba3f;
  border-radius: 3px;
  color: #fff;
  font-weight: 500 !important;
  font-size: 13px;
  font-style: normal;
`;
const SearchBoxWrapper = styled.section`
  background: transparent;
  background-image: none;
  border: 0;
  box-sizing: border-box;
  font-size: 16px;
  height: auto;
  left: 0;
  margin: 0;
  text-indent: unset;
  top: 0;
  transform: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  position: relative;
  padding: 8px 0;
`;
const SearchBox = styled.input`
  appearance: none;
  box-shadow: 2px 2px 4px rgb(0 0 0 / 12%);
  box-sizing: border-box;
  height: 44px;
  margin: 0;
  max-width: 420px;
  padding: 10px 10px 10px 40px;
  width: 100%;
  color: rgb(64, 64, 64);
  font-size: 14px;
  border: 1px solid rgb(2, 112, 208);
  border-radius: 4px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9IiMwMDM3NjciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgY2xhc3M9IiIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTUuNSAxNGgtLjc5bC0uMjgtLjI3QzE1LjQxIDEyLjU5IDE2IDExLjExIDE2IDkuNSAxNiA1LjkxIDEzLjA5IDMgOS41IDNTMyA1LjkxIDMgOS41IDUuOTEgMTYgOS41IDE2YzEuNjEgMCAzLjA5LS41OSA0LjIzLTEuNTdsLjI3LjI4di43OWw1IDQuOTlMMjAuNDkgMTlsLTQuOTktNXptLTYgMEM3LjAxIDE0IDUgMTEuOTkgNSA5LjVTNy4wMSA1IDkuNSA1IDE0IDcuMDEgMTQgOS41IDExLjk5IDE0IDkuNSAxNHoiLz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 10px 9px;
  background-size: 26px 26px;
  background-color: rgb(255, 255, 255);
`;
const HeaderMiddle = styled.div`
  background-color: ${(props) => (props.year === '2023' ? '#f6f4ed' : '#fff')};
  padding: ${(props) => (props.year === '2023' ? '0' : '15px 0')};
`;
const SecondOptions = styled.div`
  margin: 10px 0 0;
  text-align: right;
  font-size: 14px;
`;
const HeaderButtonWrapper = styled.div`
  text-align: right;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
`;
const FlexBar = styled.div`
  display: inline-block;
  vertical-align: top;
  flex: 0 1 auto;
  max-width: calc(100% - 280px);
  margin-bottom: 20px;
`;
const HeaderLoginInfo = styled.div`
  display: inline;
  border: 0;
  text-align: center;
  margin: 10px 0;
`;
const HoverBoxLogin = styled.div`
  display: flex;
  position: relative;
  border-radius: 4px;
  flex-direction: row;
  justify-content: flex-end;
  height: 38px;
  padding-right: 7px;
  cursor: pointer;
`;
const LoginDropdown = styled.div`
  right: auto;
  display: block;
  position: absolute;
  z-index: 150;
  background-color: #fff;
  width: 211px;
  margin-left: 4px;
  text-align: left;
  padding: 0 10px;
  box-shadow: 0 10px 20px rgb(0 0 0 / 10%);
  background-repeat: no-repeat;
  left: -5px;
  top: 38px;
  > a {
    color: #707070;
    display: block;
    transition: 0.5s;
    position: relative;
    left: 0;
    :hover {
      left: 5px;
      font-weight: 500;
    }
    > li {
      border: none;
      margin-bottom: 0;
      border-bottom: 1px dashed #d3d3d3;
      padding: 8px 0;
      width: 100%;
      display: flex;
      .slide {
        padding-right: 5px;
        margin-left: 7px;
        :hover {
          font-weight: 700;
        }
      }
    }
  }
`;
const UserInfoButton = styled.button`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: none;
  background: none;
`;
const WelcomeMessageWrapper = styled.span`
  display: flex;
  flex-direction: column;
  padding: 0 15px 0 8px;
`;
const WelcomeMessageTop = styled.span`
  display: block;
  font-weight: bold;
  line-height: 18px;
  text-align: left;
  flex: 0 0 auto;
`;
const WelcomeMessageBottom = styled.span`
  display: block;
  text-align: left;
  line-height: 18px;
  flex: 0 0 auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const HoverBox = styled.div`
  display: inline-block;
  position: relative;
`;
const ToXChangeButton = styled.button`
  background-color: #003766;
  border-color: #003766;
  font-size: 14px;
  padding: 8px 22px;
  line-height: 1.38;
`;
const SupportButton = styled.a`
  font-size: 14px;
  padding: 8px 22px;
  line-height: 1.38;
  margin-left: 10px;
  transition: background 0.3s;
  background-color: ${red};
  border-color: ${red};
  :hover {
    border-color: #a9370e;
    background: #a9370e;
  }
`;
const LogoWrapper = styled.div`
  display: none;
  @media (min-width: 768px) {
    display: block;
    img {
      height: 65px;
      padding: 10px 0px;
    }
  }
`;
const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

function Header({ linkPrefix, year }) {


  const logoSrc =
    year === '2023'
      ? `${linkPrefix}/images/web-logo-footer.png`
      : '/images/act_am24_logo.png';

  return (
    <StyledHeader id="header" className="no-print">
      <div className="row">
        <div className="col-12">
          <noscript>
            <p className="text-center mb-5 no-js">
              Javascript has been disabled in your browser settings. In order to
              create an optimal experience for our website visitors, Javascript
              must be enabled. Please adjust your browser settings to enable
              Javascript and press refresh before proceeding.{' '}
            </p>
          </noscript>
        </div>
      </div>
      <HeaderTop
        className="bg-theme-colored-orange border-top-theme-colored2-2px sm-text-center"
      >
        <Container className="container" $year={year}>
          <Row className="row">
            <SkipNavigationWrapper className="col-xs-12">
              <SkipNavigation
                href="#main"
                className="px-3 pt-2 pb-1 custom-skip"
              >
                Skip Navigation
              </SkipNavigation>
            </SkipNavigationWrapper>
            <div className="col-8 col-xs-7 col-sm-6 col-md-6">
              <div className="widget">
                <TopMenu className="list-inline" $year={year}>
                  <li>
                    <HeaderLink href="tel:+17034383115">
                      <StyledIcon icon={faPhone} />
                      <span>703.547.0875</span>
                    </HeaderLink>
                  </li>
                  <li>
                    <HeaderLink href="mailto:acthq@actox.org">
                      <StyledIcon icon={faEnvelope} />
                      <span>acthq@actox.org</span>
                    </HeaderLink>
                  </li>
                  <SocialIconList $year={year}>
                    <SocialIcon
                      href="https://twitter.com/SOToxicology"
                      className="top-soc"
                      target="_blank"
                      rel="noopener"
                      $year={year}
                    >
                      <FontAwesomeIcon
                        icon={faXTwitter}
                        aria-label="Twitter icon"
                      />
                    </SocialIcon>
                  </SocialIconList>
                  <SocialIconList $year={year}>
                    <SocialIcon
                      href="https://www.linkedin.com/company/society-of-toxicology-sot-/"
                      className="top-soc"
                      target="_blank"
                      rel="noopener"
                      $year={year}
                    >
                      <FontAwesomeIcon
                        icon={faLinkedinIn}
                        aria-label="linkedin icon"
                      />
                    </SocialIcon>
                  </SocialIconList>
                </TopMenu>
              </div>
            </div>
            <TopRowRigth className="col-4 col-xs-5 col-sm-6 col-md-6 d-flex">
              <TopMenu className="list-inline" $year={year}>
                <li>
                  <HeaderLink href="http://www.actox.org">
                    <StyledIcon icon={faHome} />
                    <span>ACT Home</span>
                  </HeaderLink>
                </li>
              </TopMenu>
            </TopRowRigth>
          </Row>
        </Container>
      </HeaderTop>
      <HeaderMiddle year={year}>
        <Container className="container">
          <div className="row">
            <LogoWrapper className="col-xs-12 col-sm-5 col-md-4 col-lg-3 hidden-xs pl-15">
              <a
                className="menuzord-brand pull-left flip sm-pull-center"
                href={`${linkPrefix}/`}
              >
                <img src={logoSrc} alt="SOT Society of Toxicology logo" />
              </a>
            </LogoWrapper>
            <NavWrapper className="col-xs-12 col-sm-7 col-md-8 col-lg-9 mt-10 p-0">
              <Nav />
            </NavWrapper>
          </div>
        </Container>
      </HeaderMiddle>
    </StyledHeader>
  );
}

export default Header;
