import './App.css';
import { theme } from './theme';
import Header from './components/Header';
import Footer from './components/NewFooter';
import styled, { ThemeProvider } from 'styled-components';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import BreadCrumb from './components/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { processAction } from './services/loginAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { blue, red } from './styles';

const StyledApp = styled.div`
  line-height: 1.7;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
`;
const Page = styled.main``;
const Container = styled.main`
  max-width: 1170px;
`;

const Panel = styled.div`
  text-align: left;
  h2 {
    color: ${blue} !important;
    font-weight: 600;
  }
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;

const ungatedPaths = ['/endowment', '/award'];
const isUngated = (path) => {
  if (ungatedPaths.indexOf(path) >= 0) {
    return true;
  }
  return false;
};

function App() {
  const { pathname, search } = useLocation();
  const { year } = useParams();
  const isLoginPage = (pathname && pathname.startsWith('/login')) || false;
  const isUngatedPage = pathname && isUngated(pathname);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const result = await fetch(`/api/getUserData`, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (result.status === 401) {
          if (!isLoginPage && !isUngatedPage) {
            navigate(
              '/login?redirect=' + encodeURIComponent(pathname + search)
            );
          }
        } else {
          if (isLoginPage) {
            let query = new URLSearchParams(search);
            let redirect = query.get('redirect');
            let action = query.get('action');
            if (action) {
              setLoading(true);
              let actionResult = await processAction(action, navigate);
              if (actionResult.message) {
                setError(actionResult.message);
              }
              setLoading(false);
              if (actionResult.processed) {
                return;
              }
            }
            let url = redirect && redirect.startsWith('/') ? redirect : '/';
            navigate(url, { replace: true });
            navigate(0);
          } else {
            setUserData(await result.json());
          }
        }
      } catch (e) {
        console.log(e);
        if (!isLoginPage) {
          navigate('/login?redirect=' + encodeURIComponent(pathname + search));
        }
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    // if (pathname === '/') {
    //   window.location.replace('https://www.actox.org');
    // }
  }, [pathname]);

  const linkPrefix = 'https://www.actox.org';
  const validThemeYear = theme[year] ? year : '2023';
  return (
    <ThemeProvider theme={theme[validThemeYear]}>
      <StyledApp className="App">
        <Header
          userData={userData}
          linkPrefix={linkPrefix}
          year={year || '2023'}
        />
        <BreadCrumb year={year || '2023'} />

        <Page>
          <section>
            <Container className="container">
              {loading ? (
                <Panel>
                  <Loading>
                    <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
                  </Loading>
                </Panel>
              ) : error ? (
                <React.Fragment>
                  <br />
                  <div className="alert alert-warning" role="alert">
                    {error}
                  </div>
                </React.Fragment>
              ) : (
                <Outlet />
              )}
            </Container>
          </section>
        </Page>

        <Footer linkPrefix={linkPrefix} />
        <ScrollToTop />
      </StyledApp>
    </ThemeProvider>
  );
}

export default App;
