import styled from 'styled-components';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { red } from '../styles';

const StyledFooter = styled.footer`
  text-align: center;
  color: #626262;
  background-color: #252525;
  text-align: left;
  padding-top: 60px;
  padding-bottom: 0px;
  margin-top: 50px;
  a {
    color: #bdbdbd;
    :hover {
      text-decoration: none;
    }
  }
`;
const FooterContainer = styled.div`
  max-width: 1170px;
`;
const SocialList = styled.ul`
  > li {
    display: inline-block;
    text-align: center;
  }
`;
const ContactInfoList = styled.ul`
  > li {
    display: block;
    margin: 0;
    padding: 0 10px 5px 0;
    position: relative;
  }
`;
const FooterList = styled.ul`
  margin-bottom: 40px;
  padding: 0;
  > li {
    display: block;
    margin: 0;
    padding: 0 10px 10px 0px;
    position: relative;
  }
`;
const Logo = styled.img`
  margin-bottom: 30px;
`;
const SocialLink = styled.a`
  display: block;
  background-color: ${red};
  border-radius: 50%;
  height: 25px;
  line-height: 25px;
  margin: 2px 7px 0px 0;
  width: 25px;
`;
const Heading = styled.h4`
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 1.42857143;
  font-weight: 500;
`;
const FooterBottom = styled.div`
  background-color: #1a1a1a;
  padding: 30px 0;
`;
const FooterBottomLeft = styled.div`
  text-align: center;
  margin-left: 0;
  font-size: 15px;
  color: rgba(255, 255, 255, 0.5);
  @media screen and (min-width: 992px) {
    text-align: left;
  }
`;
const FooterBottomRight = styled.div`
  border: 0;
  margin: 0;
  @media screen and (min-width: 992px) {
    text-align: left;
  }
`;
const BottomMenu = styled.div`
  font-size: 14px;
  color: #626262;
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  justify-content: left;
  & .divider {
    margin: 0 5px;
  }
  a {
    color: #626262;
  }
`;
const ColorGreen = styled.a`
  color: #84ba3f !important;
`;
const SectionHeader = styled.h6`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #003087;
  text-transform: uppercase !important;
  margin-bottom: 30px;
`;

const FooterLine = styled.div`
  width: 85%;
  border-right: solid thin lightgray;
  height: 222px;
`;

const StyledIcon = styled(FontAwesomeIcon)`
  color: #84ba3f;
  display: inline-block;
  position: absolute;
  top: 5px;
`;
const TextWithIcon = styled.div`
  margin-left: 30px;
`;
const LogoImage = styled.img`
  height: 80px;
  margin-bottom: 20px;
  max-width: 190px;
`;
const RightSideText = styled.p`
  font-size: 15px;
  line-height: 21px;
  color: #bdbdbd;
`;

const currentYear = new Date().getFullYear();

function Footer({ linkPrefix }) {
  return (
    <StyledFooter id="footer" aria-label="footer section">
      <FooterContainer
        className="container no-break"
        style={{ paddingBottom: '60px' }}
      >
        <div className="row">
          <div className="col-sm-9">
            <LogoImage src="/images/ACT-logo-tagline.png" />
            <RightSideText>
              American College of Toxicology <br />
              11190 Sunrise Valley Drive Suite <br />
              300 Reston, VA <br />
              20191-4375 <br />
              United States
            </RightSideText>
            <RightSideText>
              Tel: <a href="tel:7035470875">703.547.0875</a>
              <br />
              Fax: 703.438.3113
              <br />
              Email: <a href="mailto: acthq@actox.org">ACT Headquarters</a>
            </RightSideText>
          </div>
        </div>
      </FooterContainer>
      <FooterBottom>
        <FooterContainer className="container">
          <div className="row">
            <div className="col-md-8 text-right">
              <p>
                Copyright © American College of Toxicology. All rights
                reserved.
                <br /> You may view the ACT Privacy Policy, Code of Conduct,
                provide consent to ACT <br /> to email you communications, or
                unsubscribe to stop receiving further ACT communication.
              </p>
              <BottomMenu>
                <a
                  href={`${linkPrefix}/about/privacy-policy-and-disclaimers.asp`}
                  className="underline"
                >
                  Privacy Policy
                </a>
                <div className="divider">|</div>
                <a
                  href={`${linkPrefix}/about/code-of-conduct.asp`}
                  className="underline"
                >
                  Code of Conduct
                </a>
                <div className="divider">|</div>
                <a
                  href="mailto:acthq@actox.org?subject=Consent%20Request"
                  className="underline"
                >
                  Provide Consent
                </a>
                <div className="divider">|</div>
                <a
                  href="mailto:acthq@actox.org?subject=Unsubscribe%20Request"
                  className="underline"
                >
                  Unsubscribe
                </a>
              </BottomMenu>
            </div>
          </div>
        </FooterContainer>
      </FooterBottom>
    </StyledFooter>
  );
}

export default Footer;
