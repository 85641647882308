export const theme = {
  2023: {
    colors: {
      headerTopBg: '#003087',
      headerTopFont: '#ffffff',
      headerTopBorderTop: '2px solid #0270d0',
      headerTopBorderBottom: 'none',
      headerLinkHover: '#84ba3f',
      headerIcon: '#84ba3f',
      breadcrumbRight: '#0083ca',
      breadcrumbTitle: ' #00a88e',
      breadcrumbBorderBottom: 'none',
      materialsTitle: '#00a88e',
      materialsIcon: '#3a9eb5',
      materialsTitleBorder: '#0083ca',
      materialsLinkHover: '#0083ca',
    },
    fonts: {
      breadcrumbRight: 'Roboto, sans-serif',
      breadcrumbTitle: 'Roboto, sans-serif',
    },
  },
  2024: {
    colors: {
      headerTopBg: '#fff',
      headerTopFont: '#4a4a4a',
      headerTopBorderTop: 'none',
      headerTopBorderBottom: '1px solid #ebebeb',
      headerLinkHover: '#ec008c',
      headerIcon: '#ec008c',
      breadcrumbRight: '#0083ca',
      breadcrumbTitle: ' #000',
      breadcrumbBorderBottom: '#ebebeb',
      materialsTitle: '#000',
      materialsIcon: '#0083ca',
      materialsTitleBorder: '#ffcc04',
      materialsLinkHover: '#ec008c',
    },
    fonts: {
      breadcrumbRight: 'sans-serif',
      breadcrumbTitle: 'Raleway, sans-serif',
    },
  },
};

export const orange = '#84BA3F';
export const blue = '#004683';
export const darkBlue = '#003766';
export const gray = '#6D6E71';
export const red = '#84BA3F';
export const standardP = '#3c3b3b';
export const standardH3 = '#333333';
export const grayBoxes = '#f7f7f7';
